import instance from "../utils/axios";
import {API_URL} from "../constants/url";

class articles {
    constructor() {
        this.instance = instance;
    }

    getArticles = async (setRows, setLoader, signal) => {
        let allArticlesId = new Map();
        const token = localStorage.getItem("jwt");
        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                ...(token && {Authorization: `Bearer ${token}`}),
            },
            signal,
        };
        try {
            const response = await fetch(`${API_URL}articles/all`, options);
            if (!response.ok) {
                throw new Error("Erreur lors de la récupération du flux de données");
            }
            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let buffer = "";

            while (true) {
                const {value, done} = await reader.read();
                if (done) break;

                buffer += decoder.decode(value, {stream: true});
                let lines = buffer.split("\n"); // Supposons que chaque JSON est sur une nouvelle ligne
                buffer = lines.pop(); // Garder les données incomplètes

                for (let line of lines) {
                    if (line.trim()) {
                        try {
                            const json = JSON.parse(line);
                            if (allArticlesId.has(json.id)) {
                                allArticlesId.set(json.id, allArticlesId.get(json.id) + 1);
                            } else {
                                allArticlesId.set(json.id, 1);
                            }
                            setRows((prevRows) => {
                                if (prevRows.some((row) => row.id === json.id)) {
                                    return prevRows;
                                } else {
                                    return [...prevRows, json];
                                }
                            });
                            setLoader(false);
                        } catch (e) {
                            console.error("Erreur lors de l’analyse du JSON", e);
                        }
                    }
                }
            }
        } catch (error) {
            console.error("Erreur:", error);
        }
    };

    getArticle = async (id) => {
        return await instance.get(`articles/one?id=${id}`, {
            withCredentials: true,
        });
    };

    createArticle = async (data) => {
        return await instance.post(
            "articles/create",
            data
            // {
            //   title: data.title,
            //   description: data.description,
            //   body: data.body,
            //   publication_date: data.publication_date,
            //   is_active: data.is_active,
            //   city_id: data.city_id,
            //   author_id: data.author_id,
            //   theme_id: data.theme_id,
            //   tag_id: data.tag_id,
            //   media_id: data.media_id,
            // }
        );
    };

    updateArticle = async (values) => {
        return await instance.put("articles/update/", values, {
            withCredentials: true,
        });
    };
    getNotificationsById = async (id) => {
        return await instance.post(`articles/send-notification`,
            {
                type: "article",
                id: id,
            },
            {
                withCredentials: true,
            },
        );
    };

    deleteArticle = async (id) => {
        return await instance.delete(`articles/delete?id=${id}`, {
            withCredentials: true,
        });
    };

    searchContent = async (str) => {
        return await instance.post(
            `/articles/search?bo=true`,
            {text: str},
            {
                withCredentials: true,
            }
        );
    };
}

var articlesService = new articles();
export default articlesService;
