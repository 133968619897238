import instance from "../utils/axios";

const sendNotification = (notification) => {
    return instance.post(
        "admin/sendPushByAdmin",
        {title: notification.title, body: notification.body},
        {withCredentials: true}
    );
};

const NotificationServices = {
    sendNotification,
};

export default NotificationServices;
