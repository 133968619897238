import instance from "../utils/axios";

class agenda {
    constructor() {
        this.instance = instance;
    }

    getThemes = async () => {
        return await instance.get("agenda-themes", {
            withCredentials: true,
        });
    }

    getAllAgenda = async () => {
        return await instance.get("agenda/all", {
            withCredentials: true,
        });
    };
    createAgendaEvent = async (data) => {
        return await instance.post("agenda/create", data, {
            withCredentials: true,
        });
    }
    getAgendaEventById = async (id) => {
        return await instance.get(`agenda/one?id=${id}`, {
            withCredentials: true,
        });
    }
    deleteAgendaEvent = async (id) => {
        return await instance.delete(`agenda/delete?id=${id}`, {
            withCredentials: true,
        });
    }
    updateAgendaEvent = async (values) => {
        return await instance.put("agenda/update", values, {
            withCredentials: true,
        });
    }
    sendNotificationById = async (id) => {
        return await instance.post(`agenda/send-notification`, {
            id: id,
            type: "agenda"
        }, {
            withCredentials: true,
        });
    }

}

var agendaService = new agenda();
export default agendaService;
