import React, {useEffect, useState} from 'react';
import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Popover,
  Typography,
  Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import teletexteService from "../../services/teletexte.service";
import { toast } from "react-toastify";

const TVListManagement = () => {
  const [newItemText, setNewItemText] = useState('');
  const [items, setItems] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editItemText, setEditItemText] = useState('');

  const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString();


  useEffect(() => {
    teletexteService.getAllTeleTexte().then((response) => {
      if (response.data.success) {
        setItems(response.data.data);
      }
    });
  }, []);


  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  const handleAddItem = () => {

    const recentItemsCount = items.filter(item => item.createdAt > twentyFourHoursAgo).length;

    if (recentItemsCount >= 20) {
      toast.error("Vous avez atteint la limite de 20 éléments créés dans les dernières 24 heures. Veuillez supprimer un élément actif pour en ajouter un nouveau.", {
        position: "top-right",
        theme: "dark",
      });
      return;
    }

    teletexteService.createTeleTexte({title: newItemText}).then((response) => {
      if (response.data.success) {
        teletexteService.getAllTeleTexte().then((response) => {
          if (response.data.success) {
            setItems(response.data.data);
          }
        });

        setNewItemText("");

        toast.success("Flux TV ajouté avec succès!", {
          position: "top-right",
          theme: "dark",
        });
      } else {
        toast.error("Erreur lors de l'ajout du flux TV !", {
          position: "top-right",
          theme: "dark",
        });
      }
    });
  };
  const handleSaveEditItem = () => {
    let id = items[editIndex].id;

    const data = {
      id: id,
      title: editItemText
    }

    teletexteService.updateTeleTexte(data).then((response) => {
      if (response.data.success) {
        setItems(items.map((item) => {
          if (item.id === id) {
            item.title = editItemText;
          }
          return item;
        }));

        toast.success("Flux TV modifié avec succès!", {
          position: "top-right",
          theme: "dark",
        });
      } else {
        toast.error("Erreur lors de la modification du flux TV !", {
          position: "top-right",
          theme: "dark",
        });
      }
    });
  };

  const handleEditItem = (id, event) => {
    setAnchorEl(event.currentTarget);

    const index = items.findIndex(item => item.id === id);

    setEditIndex(index);

    setEditItemText(items[index].title);
  };

  const handleDeleteItem = (index) => {
    teletexteService.deleteTeleTexte(index).then((response) => {
      if (response.data.success) {
        setItems(items.filter((item) => item.id !== index));

        toast.success("Flux TV supprimé avec succès!", {
          position: "top-right",
          theme: "dark",
        });
      } else {
        toast.error("Erreur lors de la suppression du flux TV !", {
          position: "top-right",
          theme: "dark",
        });
      }
    });
  };

  const handleClosePopover = () => {
    setEditIndex(null);
    setEditItemText("");
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);


  return (
      <div className='ml-2 mt-1'>
        <Typography variant="caption" sx={{ color: "#16324D", fontSize: "18px", fontWeight: "bold" }} gutterBottom>
          Flux Maritima TV
        </Typography>
        <TextField
            label="Nouvel élément TV-Flux"
            value={newItemText}
            onChange={(e) => setNewItemText(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ marginTop: "3px", marginBottom: "10px" }}

        />
        <Button variant="contained" color="secondary" onClick={handleAddItem}>
          Ajouter un flux TV
        </Button>
        <List >
          {items.map((item) => (
              <ListItem key={item.id}>
                {item.createdAt > twentyFourHoursAgo ? (
                    <StyledBadge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        variant="dot"
                        sx={{ marginRight: 1 }}
                    />
                ) : (
                    <Badge variant="dot" color="error" badgeContent={" "} sx={{ marginRight: 1 }} />
                )}
                <ListItemText primary={<span className='font-bold'>{item.title}</span>} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="edit" onClick={(e) => handleEditItem(item.id, e)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteItem(item.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
          ))}
        </List>


        <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
        >
          <Box p={2}>
            <Typography variant="h6" gutterBottom>
              Modifier l'élément TV-Flux
            </Typography>
            <TextField
                label="Element TV-Flux"
                value={editItemText}
                onChange={(e) => setEditItemText(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
            />
            <Button sx={{ marginRight: 1 }} variant="contained" color="success" onClick={handleSaveEditItem}>
              Enregistrer
            </Button>
            <Button variant="contained" color="error" onClick={handleClosePopover}>
              Annuler
            </Button>
          </Box>
        </Popover>
      </div>
  );
};

export default TVListManagement;
