import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import Home from "@mui/icons-material/Home";
import PersonIcon from '@mui/icons-material/Person';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import LogoutIcon from '@mui/icons-material/Logout';
import Typography from "@mui/material/Typography";
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArticleIcon from "@mui/icons-material/Article";
import logo from "../../assets/Logo MARITIMA-horizontal-Blanc.png";
import { Avatar, Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import MicIcon from '@mui/icons-material/Mic';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AdminService from "../../services/admin.service";
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import ShopIcon from '@mui/icons-material/Shop';
import { useTheme, useMediaQuery } from "@mui/material";



const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const handleLogout = () => {

  AdminService.logout()
    .then((response) => {
      localStorage.clear();
      window.location.href = "/login";
    })
    .catch((error) => {
      localStorage.clear();
    })
}
const menu = [
  {
    label: "Tableau de bord",
    icon: <Home />,
    link: "/dashboard",
  },
  {
    label: "Articles",
    icon: <ArticleIcon />,
    link: "/articles",
  },
  {
    label: "TV",
    icon: <OndemandVideoIcon />,
    link: "/tv",
  },
  {
    label: "Régie Pub",
    icon: <ShopIcon />,
    link: "/regie-pub",
  },
  {
    label: "Podcast",
    icon: <MicIcon />,
    link: "/podcast",
  },
  {
    label: "Bibliotheque d'images",
    icon: <PhotoLibraryIcon />,
    link: "/medias",
  },
  {
    label: "Agenda",
    icon: <CalendarTodayIcon />,
    link: "/agenda",
  },
  {
    label: "Pages annexes",
    icon: <DescriptionIcon />,
    link: "#",
    onClick: "open",
    category: [
      {
        label: "Ecouter et voir Maritima",
        link: "/annex-pages/ecouter-et-voir-Maritima",
      },
      {
        label: "Devenir annonceur",
        link: "/annex-pages/devenir-annonceur",
      },
      {
        label: "Qui sommes nous",
        link: "/annex-pages/qui-sommes-nous",
      },
      {
        label: "Recrutement",
        link: "/annex-pages/recrutement",
      },
      // {
      //   label: "Charte déontologique",
      //   link: "/annex-pages/deontology",
      // },
      {
        label: "Mention légales",
        link: "/annex-pages/mentions-legales",
      },
      {
        label: "CGU",
        link: "/annex-pages/cgu",
      },
    ],
  },
  {
    label: "Radio",
    icon: <SettingsInputAntennaIcon />,
    link: "/radio",
  },
  {
    label: "Utilisateurs",
    icon: <PersonIcon />,
    link: "/admin",
  },
  {
    label: "Push Notifications",
    icon: <NotificationsIcon />,
    link: "/notification",
  },
  {
    label: "Abonnés newsletter",
    icon: <AttachEmailIcon />,
    link: "/newsletter",
  },
  {
    label: "Se déconnecter",
    icon: <LogoutIcon />,
    link: "/login",
    onClick: handleLogout,
  },
];

const Menu = ({ children, category, breadCrumb, link, buttonText, openParams = false }) => {
  //drawer open state
  const theme = useTheme();

  const [drawerOpen, setDrawerOpen] = useState(true);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const displayButtomLinkResponsive = category === "Éditer article"
  //set the drawer open state in local storage
  const toggleDrawer = () => {
    const newDrawerOpen = !drawerOpen;
    setDrawerOpen(newDrawerOpen);
    localStorage.setItem("drawerOpen", JSON.stringify(newDrawerOpen));
  };

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [open, setOpen] = useState(openParams);
  const pictureUrl = localStorage.getItem('pictureUrl');
  const name = localStorage.getItem('name');
  const email = localStorage.getItem('account');

  const drawerWidth = 240;


  useEffect(() => {
    const storedSelectedIndex = localStorage.getItem("selected");
    const storedDrawerOpen = JSON.parse(localStorage.getItem("drawerOpen"));
    if (storedSelectedIndex) {
      setSelectedIndex(Number(storedSelectedIndex));
    }
    if (storedDrawerOpen !== null) {
      setDrawerOpen(storedDrawerOpen);
    }
  }, []);

  const handleMenuItemClick = (menuIndex) => {
    setSelectedIndex(menuIndex);

    localStorage.setItem("selected", menuIndex);
  };

  return (
    <div>
      <Box sx={{ display: "flex", overflowY: "auto" }}>
        <div className={`${drawerOpen ? "absolute " : "relative"} `}>
          <Button sx={{ position: "absolute", zIndex: 99999999, left: drawerOpen ? 200 : -10, color: drawerOpen ? "white" : "black" }} onClick={toggleDrawer}><MenuIcon /> </Button>
        </div>
        {drawerOpen && (
          <Drawer
            variant="permanent"
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              // zIndex: 9999,
              [`& .MuiDrawer-paper`]: {
                display: drawerOpen ? 'block' : 'none',
              },
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Paper elevation={0} sx={{ backgroundColor: "#16324D" }}>
                <Divider />

                <img src={logo} alt={'Logo Maritima'} style={{ width: "100%", maxWidth: 160, paddingTop: 30, paddingBottom: 30, marginLeft: 50, marginRight: 50 }} />

                <Divider />

                <Box
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    minHeight: "100vh",
                    backgroundColor: "#16324D",
                  }}
                >
                  <Divider />

                  {menu.map((item, menuIndex) => (
                    <div style={{ display: "flex", flexDirection: "column" }} key={menuIndex}>
                      <Link
                        onClick={() => {
                          //for logout
                          if (item.onClick) {
                            if (item.onClick !== "open") {
                              item.onClick();
                            } else {
                              setOpen(!open)
                            }
                          }
                        }}
                        to={item.link}
                        className="nav-link"
                        style={{ display: "flex" }}>

                        <ListItemButton
                          onClick={() => {
                            //for logout
                            if (item.onClick) {
                              if (item.onClick !== "open") {
                                item.onClick();
                              } else {
                                setOpen(!open)
                              }
                            }
                            handleMenuItemClick(menuIndex);
                          }}
                          selected={selectedIndex === menuIndex}
                        >
                          <ListItemIcon style={{ color: "white" }}>
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.label}
                            primaryTypographyProps={{
                              fontSize: 13,
                              fontWeight: selectedIndex === menuIndex ? 600 : 400,
                            }}
                            style={{ color: "white" }}
                          />
                          {item.category && item.category.length > 0 && open && (
                            <ExpandLess style={{ color: "white" }} />
                          )}
                          {item.category && item.category.length > 0 && !open && (
                            <ExpandMore style={{ color: "white" }} />
                          )}
                        </ListItemButton>
                      </Link>
                      {item.category && item.category.length > 0 && (
                        <Collapse in={open} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {item.category.map((category, categoryIndex) => (
                              <Link
                                to={category.link}
                                className="nav-link"
                                style={{ display: "flex" }}
                                key={categoryIndex}
                              >
                                <ListItemButton sx={{ pl: 4 }} key={categoryIndex}>
                                  <ListItemText
                                    key={categoryIndex}
                                    primary={category.label}
                                    primaryTypographyProps={{
                                      fontSize: 13,
                                      fontWeight: selectedIndex === menuIndex ? 600 : 400,
                                    }}
                                    style={{ color: "white", alignSelf: "center" }}
                                  />
                                </ListItemButton>
                              </Link>
                            ))}
                          </List>
                        </Collapse>
                      )}
                    </div>
                  ))}
                </Box>
              </Paper>
            </Box>
          </Drawer>
        )}
        <Box component="main" sx={{ flexGrow: 1, p: isSmallScreen ? 0 : 4, mt: 5, ml: drawerOpen ? 0 : 2 }}>
          <div style={{ minHeight: "100vh" }}>
            <div className={"mb-3"} style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {category ? (
                  <Typography variant={isSmallScreen ? "h7" : "h5"} key="3" color="text.primary">
                    {category}
                  </Typography>
                ) : null}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {(!isSmallScreen || !displayButtomLinkResponsive) && (
                    link && buttonText ?
                      link === "/regie-pub/create" ? (

                        <Button
                          className={"mx-5"}
                          sx={{ borderRadius: 2.5, boxShadow: 0 }}
                          color="secondary"
                          size="medium"
                          variant="contained"
                          startIcon={<AddCircleIcon />}
                          onClick={() => {
                            window.location.href = link;
                          }}
                        >
                          {buttonText}
                        </Button>
                      ) : (

                        <Link to={link}>
                          <Button
                            className={isSmallScreen ? "mx-3" : "mx-5"}
                            sx={{ borderRadius: 2.5, boxShadow: 0 }}
                            color="secondary"
                            size={isSmallScreen ? "small" : "medium"}
                            variant="contained"
                            startIcon={<AddCircleIcon />}
                          >
                            {buttonText}
                          </Button>
                        </Link>
                      )

                      : null
                  )}

                  <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>

                    <StyledBadge
                      sx={{ marginRight: isSmallScreen ? 2.5 : 0 }}
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      variant="dot"
                    >
                      <Avatar
                        alt="Profile picture"
                        src={pictureUrl ? pictureUrl : ''}
                      />
                    </StyledBadge>
                    {!isSmallScreen && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 1 }}>
                        <p style={{ fontWeight: "bold", marginBottom: 0, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{name ? name : ''}</p>
                        <p style={{ marginBottom: 0, fontSize: 14, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{email ? email : ''}</p>
                      </Box>
                    )}
                  </Box>
                </Box>
              </div>

              <div className="flex">
                {breadCrumb && (
                  breadCrumb.map((item, index) => (
                    <div className="flex" key={index}>
                      <Link style={{ textDecoration: 'none' }} to={item.to}> <Typography fontWeight={"bold"} key="3" color="secondary">{item.name}</Typography></Link>
                      {index < breadCrumb.length - 1 && (
                        <Typography fontWeight={"bold"} key="3" color="secondary">&nbsp;{">"}&nbsp;</Typography>
                      )}
                    </div>
                  ))
                )}
              </div>

            </div>
            <div>{children}</div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Menu;
