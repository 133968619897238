import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography,
    Box,
    Modal,
    Grid,
    Alert,
    TextField,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/system';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Badge from '@mui/material/Badge';
import CoverImageSelector from '../../pages/articles/components/ConverImageSelector';
import { AddImageModal } from '../../pages/articles/components/AddImageModal';
import { ModalMedia } from '../../pages/articles/components/ModalMedia';
import gameConstestService from '../../services/gameContest.service';
import { DatePicker } from '../../pages/articles/components/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CustomModalSuccess from '../../pages/articles/components/CustomModalSuccess';
import ConfirmDeleteModal from '../../pages/articles/components/ConfirmDeleteModal';
import { IMAGE_URL } from "../../constants/url";
import GetColorImage from './GetColorImage';
import instance from '../../utils/axios';
import { useTheme, useMediaQuery } from "@mui/material";


const Gamecontests = () => {
    // Responsive 
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    // 
    const [imageUrl, setImageUrl] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [color, setColor] = useState("");
    const [items, setItems] = useState([]);
    const [orderedItems, setOrderedItems] = useState(items);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [draggedIndex, setDraggedIndex] = useState(null);
    const [infoCreate, setInfoCreate] = useState({
        authors: [],
        city: [],
        tags: [],
        media: [],
        themes: [],
    });


    useEffect(() => {
        instance.get("articles/info-create").then((res) => {
            setInfoCreate({
                authors: res.data.authors,
                city: res.data.city,
                tags: res.data.tags,
                media: res.data.media,
                themes: res.data.themes,
            });
        });
    }, []);



    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));

    const openMediaPicker = () => {
        setOpen(true);
    };

    const getGameContest = () => {
        gameConstestService.getGameContest().then((res) => {
            setItems(res.data.map((item) => {
                return {
                    id: item.id,
                    title: item.title,
                    description: item.description,
                    url: item.url,
                    date_start: Number(item.date_start),
                    date_end: Number(item.date_end),
                    media_id: item.medias.map((media) => media.id),
                    btn_txt: item.btn_txt,
                    color: item.color,
                    order: item.order,
                    mediaItems: item.medias,
                }
            }));
        });
    };

    const [openModalConfirmModal, setOpenModalConfirmModal] = useState(false);
    const handleCloseModalConfirmModal = () => {
        setOpenModalConfirmModal(false);
    };
    const handleOpenModalConfirmModal = (index) => {
        setIndexToDelete(index)
        setOpenModalConfirmModal(true);
    };


    useEffect(() => {
        getGameContest();

    }, []);

    const [openModalSuccess, setOpenModalSuccess] = useState(false);
    const handleCloseModalSuccess = () => {
        setOpenModalSuccess(false);
    };
    const handleOpenModalSuccess = () => setOpenModalSuccess(true);
    const [indexToDelete, setIndexToDelete] = useState(null);
    const [open, setOpen] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [openCroppImage, setOpenCroppImage] = useState(false);
    const [error, setError] = useState(false);

    const [editItemData, setEditItemData] = useState({
        title: '',
        description: '',
        url: '',
        date_start: Math.round(new Date().getTime() / 1000),
        date_end: Math.round(new Date().getTime() / 1000),
        media_id: [],
        btn_txt: '',
        color: "",
        mediaItems: [],
    });
    const handleOpenCroppImage = () => {
        setOpenCroppImage(true)
    };
    const closeMediaPicker = () => {
        setOpen(false);
    };
    const handleCloseCroppImage = ({ openMediapicker = false }) => {
        if (openMediapicker) {
            setOpenCroppImage(false)
            openMediaPicker();
        } else {
            setOpenCroppImage(false)
        }
    };


    const handleAddItem = (newItemData) => {
        if (isValidData(newItemData)) {
            //if different of null is update
          const regex = new RegExp('^http')
          newItemData.url = regex.test(newItemData.url)
              ? newItemData.url
              : "https://" + newItemData.url;
            if (editIndex !== null) {
                const updatedItems = [...items];
                updatedItems[editIndex] = newItemData;
                gameConstestService.updateGameContest(newItemData).then((res) => {
                    if (res.status === 200) {
                        setError(false);
                        handleOpenModalSuccess();
                        setIsModalOpen(false);
                        setEditItemData({
                            title: '',
                            description: '',
                            url: '',
                            date_start: null,
                            date_end: null,
                            media_id: [],
                            btn_txt: '',
                            color: "",
                            order: ""

                        });
                    }
                }
                );
                setItems(updatedItems);
                setEditIndex(null);
            } else {
                // If adding a new item
                gameConstestService.createGameContest(newItemData).then((res) => {
                    // response dont get data so we need to call getGameContest again
                    if (res.status === 200) {
                        setError(false);
                        getGameContest();
                        handleOpenModalSuccess();
                        setIsModalOpen(false);
                        setEditItemData({
                            title: '',
                            description: '',
                            url: '',
                            date_start: null,
                            date_end: null,
                            media_id: [],
                            btn_txt: '',
                            color: "",
                            order: ""

                        });
                    }
                }
                );
            }
        } else {
            setError(true);
        }
    };

    const handleEditItem = (index) => {
        setEditIndex(index);
        setEditItemData(items[index]);
        setIsModalOpen(true);
    };

    const handleDeleteItem = () => {
        const updatedItems = [...items];
        gameConstestService.deleteGameContest(items[indexToDelete].id).then((res) => {
            if (res.status === 200) {
                handleCloseModalConfirmModal();
                handleOpenModalSuccess();
                setItems(updatedItems);
                updatedItems.splice(indexToDelete, 1);
            }
        }
        );
    };
    const handleDragStart = (e, index) => {
        e.dataTransfer.setData('text/plain', index);
        setDraggedIndex(index);
    };

    const handleDragOver = (e, index) => {
        e.preventDefault();
        if (index !== hoveredIndex) {
            setHoveredIndex(index);
        }
    };

    const handleDrop = (e, dropIndex) => {
        e.preventDefault();

        const dragIndex = parseInt(e.dataTransfer.getData('text/plain'), 10);
        setHoveredIndex(null);
        setDraggedIndex(null);
        // Créez une copie du tableau pour ne pas modifier l'état directement
        const newOrderedItems = [...orderedItems];

        // Réorganisez l'ordre des éléments
        const [draggedItem] = newOrderedItems.splice(dragIndex, 1);
        newOrderedItems.splice(dropIndex, 0, draggedItem);

        // Mettez à jour l'état avec le nouvel ordre
        setOrderedItems(newOrderedItems);
        gameConstestService.updateOrderGameContest({ data: newOrderedItems })
            .then((res) => {
                getGameContest()
            })
            .catch((e) => {
                console.log(e)
            })
    };

    const handleCloseModal = () => {
        setError(false);
        setEditIndex(null);
        setIsModalOpen(false);
        setEditItemData({
            url: '',
            date_start: "",
            date_end: "",
            media_id: [],
            btn_txt: '',
        });
    };
    const openModal = Boolean(isModalOpen);
    useEffect(() => {
        if (openModal && editItemData && editItemData.media_id.length > 0) {
            const findImageInMedias = infoCreate.media.filter((media) => editItemData.media_id.includes(media.id));
            setImageUrl(`${IMAGE_URL}${findImageInMedias[0]?.url}`);
        }
    }, [openModal, editItemData]);

    useEffect(() => {
        setOrderedItems(items)
    }, [items])

    useEffect(() => {
        if (color !== "") {
            setEditItemData({ ...editItemData, color: color })
        }
    }, [color])

    const isValidData = (data) => {
        return data.title !== '' && data.description !== '' && data.url !== '' && data.date_start !== "" && data.date_end !== "" && data.media_id.length !== 0 && data.btn_txt !== '';
    };

    return (
        <div className="ml-2 mt-1">
            <Typography variant="caption" sx={{ color: '#16324D', fontSize: '18px', fontWeight: 'bold' }} gutterBottom>
                Gestion Opération Antenne
            </Typography>
            <Button sx={{ marginTop: "5px" }} variant="contained" color="secondary" onClick={() => setIsModalOpen(true)}>
                Ajouter une OP antenne
            </Button>
            <List>
                {orderedItems.length > 0 && orderedItems.map((item, index) => {
                    const timestamp = Math.floor(Date.now() / 1000);
                    const isRunning = item.date_start < timestamp && item.date_end > timestamp;
                    return (
                        <div
                            key={index}
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={(e) => handleDragOver(e, index)}
                            onDrop={(e) => handleDrop(e, index)}
                            style={{
                                border: `1px solid ${index === hoveredIndex ? 'blue' : 'transparent'}`,
                                backgroundColor: index === draggedIndex ? '#F8F8F8' : 'transparent',
                                borderRadius: "5px",
                                cursor: index === draggedIndex ? 'grabbing' : 'grab'
                            }}
                        >
                            <ListItem key={index} sx={{ pl: 0 }}>
                                <DragHandleIcon style={{ fontSize: '1em', marginRight: "5px" }} />
                                {isRunning ? (
                                    <StyledBadge
                                        overlap="circular"
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                        variant="dot"
                                        sx={{ marginRight: 1 }}
                                    />
                                ) : (
                                    <Badge variant="dot" color="error" badgeContent={" "} sx={{ marginRight: 1 }} />
                                )}
                                <ListItemText primary={<span className="font-bold">{item.title}</span>} />
                                <ListItemSecondaryAction>
                                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditItem(index)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete" onClick={() => handleOpenModalConfirmModal(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        </div>

                    )
                })}
            </List>

            <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-title" aria-describedby="modal-description">

                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: isSmallScreen ? 400 : 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 6,
                        overflowY: 'auto',
                        maxHeight: '100%',

                    }}
                >
                    <Typography id="modal-title" variant="h6" component="h2">
                        Opération Antenne
                    </Typography>
                    <TextField
                        label="Titre"
                        value={editItemData.title}
                        onChange={(e) => setEditItemData({ ...editItemData, title: e.target.value })}
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: 1, marginTop: 2 }}
                        inputProps={{ maxLength: 35 }}
                    />
                    <TextField
                        label="Description"
                        value={editItemData.description}
                        onChange={(e) => setEditItemData({ ...editItemData, description: e.target.value })}
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: 1 }}
                    />
                    <TextField
                        label="URL"
                        value={editItemData.url}
                        onChange={(e) => setEditItemData({ ...editItemData, url: e.target.value })}
                        fullWidth
                        variant="outlined"
                        sx={{ marginBottom: 1 }}

                    />
                    <Box sx={{ mb: 3 }}>

                        <Box sx={{ m: 0 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <DatePicker
                                    setDataArticle={setEditItemData}
                                    dataArticle={editItemData}
                                    propertyName={"date_start"}
                                    title={"Date de début"}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <DatePicker
                                    setDataArticle={setEditItemData}
                                    dataArticle={editItemData}
                                    propertyName={"date_end"}
                                    title={"Date de fin"}
                                />
                            </LocalizationProvider>
                        </Box>
                    </Box>
                    <Grid item xs={6}>
                        <TextField
                            label="Libellé du bouton"
                            value={editItemData.btn_txt}
                            onChange={(e) => setEditItemData({ ...editItemData, btn_txt: e.target.value })}
                            fullWidth
                            // margin="normal"
                            variant="outlined"
                            inputProps={{ maxLength: 20 }}

                        />
                    </Grid>
                    <Box sx={{ marginBottom: isSmallScreen ? 5 : 7 }}>

                        <CoverImageSelector
                            dataArticle={editItemData}
                            setDataArticle={setEditItemData}
                            infoCreate={infoCreate}
                            setInfoCreate={setInfoCreate}
                            openMediaPicker={openMediaPicker}
                            setOpen={setOpen}
                            handleOpenCroppImage={handleOpenCroppImage}
                        />
                    </Box>
                    {openModal && (
                        <GetColorImage imageUrl={imageUrl} setColor={setColor} color={color} />
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button sx={{ marginRight: "10px" }} variant="contained" color="secondary" onClick={() => handleAddItem(editItemData)}>
                            Enregistrer
                        </Button>
                        <Button variant="contained" color="error" onClick={handleCloseModal}>
                            Annuler
                        </Button>
                    </Box>
                    {error ? (
                        <Alert sx={{ marginBottom: 1, marginTop: 3, top: 27, right: 750 }} severity="error">
                            Tous les champs sont obligatoires
                        </Alert>
                    ) : null}
                </Box>
            </Modal>
            {open && (
                <ModalMedia
                    open={open}
                    handleClose={closeMediaPicker}
                    handleSave={closeMediaPicker}
                    setDataArticle={setEditItemData}
                    dataArticle={editItemData}
                    infoCreate={infoCreate}
                    setInfoCreate={setInfoCreate}

                />
            )}
            {openCroppImage && (
                <AddImageModal
                    open={openCroppImage}
                    handleCloseCroppImage={handleCloseCroppImage}
                    setInfoCreate={setInfoCreate}
                    infoCreate={infoCreate}
                    dataArticle={editItemData}
                    setDataArticle={setEditItemData}

                />
            )
            }
            <CustomModalSuccess
                open={openModalSuccess}
                onClose={handleCloseModalSuccess}
                title={"Succès"}
                description={"Le jeu concours a été enregistré avec succès."}

            />
            <ConfirmDeleteModal
                open={openModalConfirmModal}
                onClose={handleCloseModalConfirmModal}
                onConfirm={handleDeleteItem}
            />

        </div>
    );
};

export default Gamecontests;
